<template>
	<div>
		<!--start Banner section-->
		<section class="bannersc">
			<div class="container">
				<div class="row">
					<div class="col-md-6 order-1 order-md-12">
						<div class="banner-text">
							<h1 v-html="$t('home.banner.bannertitle')"></h1>
							<p v-html="$t('home.banner.bannertext')"></p>
							<div class="btnbanner">
								<a href="mailto:hello@realtalent.it" class="fristbtn">{{
									$t("home.banner.btn1")
								}}</a>
								<a href="tel:0694503568" class="secondbtn">{{
									$t("home.banner.btn2")
								}}</a>
							</div>
						</div>
					</div>
					<div class="col-md-6 order-12 order-md-1">
						<div class="bannercircle_img">
							<img src="@/assets/gifn2.gif" alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>
		<!--END  -->

		<!--Start Logo top company -->
		<section class="comapnylogo">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<h3>{{ $t("home.company") }}</h3>
						<ul class="topcomapy">
							<li class="logo1">
								<img src="@/assets/images/trust/logo-1.svg" alt="airbnb" />
							</li>
							<li class="logo2">
								<img src="@/assets/images/trust/logo-2.svg" alt="google" />
							</li>
							<li class="logo3">
								<img src="@/assets/images/trust/logo-3.svg" alt="duolingo" />
							</li>
							<li class="logo4">
								<img src="@/assets/images/trust/logo-4.svg" alt="shopify" />
							</li>
							<li class="logo5">
								<img src="@/assets/images/trust/logo-5.svg" alt="uber" />
							</li>
							<li class="logo6">
								<img src="@/assets/images/trust/logo-6.svg" alt="zoom" />
							</li>
							<li class="logo7">
								<img src="@/assets/images/trust/logo-7.svg" alt="facebook" />
							</li>
							<li class="logo8">
								<img src="@/assets/images/trust/logo-8.svg" alt="amazon" />
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		<!--END -->

		<!--Main Content section START-->
		<section class="mainsc_content">
			<div class="container">
				<!--section title -->
				<div class="row">
					<div class="col-md-12">
						<div class="section_title">
							<h2>{{ $t("home.serviceSectionTitle") }}</h2>
							<p>{{ $t("home.servicesSectionSubtitle") }}</p>
						</div>
					</div>
				</div>
				<!--END-->

				<!--6 box style-->
				<div class="row">
					<!--box col start-->
					<div class="col-md-4">
						<div class="hotbox">
							<div class="iconscmain iconsc-1"></div>
							<h3>{{ $t("home.serviceTitleOne") }}</h3>
							<p>{{ $t("home.serviceInfoOne") }}</p>
						</div>
					</div>
					<!--END box col-->
					<!--box col start-->
					<div class="col-md-4">
						<div class="hotbox">
							<div class="iconscmain iconsc-2"></div>
							<h3>{{ $t("home.serviceTitleTwo") }}</h3>
							<p>{{ $t("home.serviceInfoTwo") }}</p>
						</div>
					</div>
					<!--END box col-->
					<!--box col start-->
					<div class="col-md-4">
						<div class="hotbox">
							<div class="iconscmain iconsc-3"></div>
							<h3>{{ $t("home.serviceTitleThree") }}</h3>
							<p>{{ $t("home.serviceInfoThree") }}</p>
						</div>
					</div>
					<!--END box col-->
					<!--box col start-->
					<div class="col-md-4">
						<div class="hotbox">
							<div class="iconscmain iconsc-4"></div>
							<h3>{{ $t("home.serviceTitleFour") }}</h3>
							<p>{{ $t("home.serviceInfoFour") }}</p>
						</div>
					</div>
					<!--END box col-->
					<!--box col start-->
					<div class="col-md-4">
						<div class="hotbox">
							<div class="iconscmain iconsc-5"></div>
							<h3>{{ $t("home.serviceTitleFive") }}</h3>
							<p>{{ $t("home.serviceInfoFive") }}</p>
						</div>
					</div>
					<!--END box col-->
					<!--box col start-->
					<div class="col-md-4">
						<div class="hotbox">
							<div class="iconscmain iconsc-6"></div>
							<h3>{{ $t("home.serviceTitleSix") }}</h3>
							<p>{{ $t("home.serviceInfoSix") }}</p>
						</div>
					</div>
					<!--END box col-->
				</div>
				<!--END-->

				<!--2 col section-->
				<div class="row">
					<div class="col-md-12">
						<div class="twocolsc">
							<div class="row">
								<!--col-6-->
								<div class="col-md-6">
									<div class="colsc-box fristsc">
										<h3>{{ $t("home.middleSectionTitleOne") }}</h3>
										<div v-html="$t('home.middleSectionDetailsOne')"></div>
										<div class="colimg">
											<img src="@/assets/images/img-20.svg" alt="" />
										</div>
									</div>
								</div>
								<!--end-->
								<!--col-6-->
								<div class="col-md-6">
									<div class="colsc-box secondsc">
										<h3>{{ $t("home.middleSectionTitleTwo") }}</h3>
										<div v-html="$t('home.middleSectionDetailsTwo')"></div>
										<div class="colimg">
											<img src="@/assets/images/img-21.svg" alt="" />
										</div>
									</div>
								</div>
								<!--end-->
							</div>
						</div>
					</div>
				</div>
				<!--END-->
				<!--boxstyle2-->
				<div class="row text-2col-box align-items-center">
					<div class="col-md-6 order-1 order-md-12">
						<div class="textboxsc">
							<h3 class="homewhy">
								<span>{{ $t("home.lastSectionTitle") }}</span>
							</h3>
							<div v-html="$t('home.lastSectionDetails')"></div>
						</div>
					</div>
					<div class="col-md-6 order-12 order-md-1">
						<div class="box-col-sc_img">
							<img src="@/assets/images/img-22.svg" alt="" />
						</div>
					</div>
				</div>
				<!--END-->
			</div>
		</section>
		<!--END main section -->
		<section class="keyvalue">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<h3>{{ $t("home.keyvalue.title") }}</h3>
					</div>
					<!--box-1-->
					<div class="col-md-4">
						<div class="key-value-box">
							<img src="@/assets/images/shape01.svg" alt="" />
							<h4>{{ $t("home.keyvalue.subtitleOne") }}</h4>
						</div>
					</div>
					<!--end-->
					<!--box-1-->
					<div class="col-md-4">
						<div class="key-value-box">
							<img src="@/assets/images/shape02.svg" alt="" />
							<h4>{{ $t("home.keyvalue.subtitleTwo") }}</h4>
						</div>
					</div>
					<!--end-->
					<!--box-1-->
					<div class="col-md-4">
						<div class="key-value-box">
							<img src="@/assets/images/shape03.svg" alt="" />
							<h4>{{ $t("home.keyvalue.subtitleThree") }}</h4>
						</div>
					</div>
					<!--end-->
				</div>
			</div>
		</section>
		<!--call a section start-->
		<section class="callactionsc">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<h3>{{ $t("callActionHome.title") }}</h3>
						<div v-html="$t('callActionHome.content')"></div>
						<router-link
							:to="`/${$i18n.locale}/contact-us`"
							class="get_scbtn"
							>{{ $t("callActionHome.btn") }}</router-link
						>
					</div>
				</div>
			</div>
		</section>
		<!--End call a section -->
	</div>
</template>

<script>
	import MainPage from "@/layouts/Main_Layout.vue";
	import "@fancyapps/ui/dist/fancybox.css";
	import "@fancyapps/ui/src/Fancybox/Fancybox.js";
	export default {
		//this for layout call
		created() {
			this.$parent.$emit("update:layout", MainPage);
		},
		//this for layout render
		render() {
			return this.$slots.default[0];
		},
		//Meta info title + description + others meta
		metaInfo() {
			return {
				title: this.$t("metaInfo.homeTitle"),
				htmlAttrs: {
					lang: this.$i18n.locale,
				},
				meta: [{ name: "description", content: this.$t("metaInfo.homeDesc") }],
			};
		},
		//end meta info
	};
</script>

<style>
	.owl-theme .owl-nav [class*="owl-"] {
		color: transparent;
		background: #ffe3c2;
	}
	.owl-theme .owl-nav [class*="owl-"]:hover {
		background: #ffe3c2;
		color: transparent;
	}
	.bannercircle_img video {
		width: 100%;
		max-width: 100%;
	}
   
</style>
