<template>
	<div>
		<!--Main Content section START-->
		<section class="mainsc_content legal-main-section">
			<div class="container">
				<div class="legal-content text-center error404">
					<div class="row">
						<div class="col-md-2"></div>
						<div class="col-md-8">
							<h1>{{ $t("errorPage.title") }}</h1>
							<p>{{ $t("errorPage.info") }}</p>
							<div class="btnbanner error">
								<router-link :to="`/${$i18n.locale}`" class="fristbtn">{{
									$t("errorPage.btn1")
								}}</router-link>
								<router-link
									:to="`/${$i18n.locale}/contact-us`"
									class="secondbtn"
									>{{ $t("errorPage.btn2") }}</router-link
								>
							</div>
						</div>
						<div class="col-md-2"></div>
					</div>
				</div>
			</div>
		</section>
		<!--END main section-->
	</div>
</template>

<script>
	import MainPage from "@/layouts/Main_Layout.vue";
	export default {
		name: "NotFound",
		//this for layout call
		created() {
			this.$parent.$emit("update:layout", MainPage);
		},
		//this for layout render
		render() {
			return this.$slots.default[0];
		},
		//Meta info title + description + others meta
		metaInfo() {
			return {
				title: this.$t("metaInfo.404Title"),
				htmlAttrs: {
					lang: this.$i18n.locale,
				},
				meta: [{ name: "description", content: this.$t("metaInfo.404Desc") }],
			};
		},
		//end meta info
	};
</script>

<style lang="scss" scoped></style>