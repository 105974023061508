<template>
  <div>
      <header id="nav" :class="{sticky:active}">
        <div class="container laptopshow">
            <div class="row">
                <div class="col-md-3">
                   <div class="logo"> 
                     <router-link to="/en" v-if="$i18n.locale == 'en'">
                     <img src="@/assets/images/new-logo.svg" alt="Real Talent">
                     </router-link>
                     <router-link to="/" v-else>
                     <img src="@/assets/images/new-logo.svg" alt="Real Talent">
                     </router-link>
                    </div>
                </div>
                <div class="col-md-9 scrightheader justify-content-end"> 
                        <ul class="navscmenu"> 
                            <li><router-link :to="`/${$i18n.locale}/why`">{{$t('header.nav.why')}}</router-link></li> 
                            <li><router-link :to="`/${$i18n.locale}/our-identity`">{{$t('header.nav.identity')}}</router-link></li> 
                        </ul>                      
                       
                        <ul class="applysc">
                            <li class="hire_Us"> <router-link :to="`/${$i18n.locale}/contact-us`">{{$t('header.nav.hireUs')}}</router-link></li>
                           
                        </ul>
                          <Language />  
                     <!--   <ul>
                            <li class="sidebardesktop"> 
                              <router-link  data-bs-toggle="offcanvas"
                               to="#offcanvasSc"   aria-controls="offcanvasSc">
                                <img src="@/assets/images/menu-side-bar-2.svg" alt="">  
                              </router-link> 
                            </li>
                        </ul> -->
                </div>
            </div>            
        </div>
        <div class="container mobileshow">            
            <div class="row align-items-center">
                <div class="col-sm-6 col-6">
                   <div class="logo"> 
                     <router-link to="/en" v-if="$i18n.locale == 'en'">
                     <img src="@/assets/images/new-logo.svg" alt="">
                     </router-link>
                      <router-link to="/" v-else>
                     <img src="@/assets/images/new-logo.svg" alt="">
                     </router-link>
                     </div>
                </div>
                <div class="col-sm-6 col-6 justify-content-end">                         
                        <ul class="mobile_humberbag">
                            <li class="sidebarmobile"  >
                              <router-link  data-bs-toggle="offcanvas"
                               to="#offcanvasMB"   aria-controls="offcanvasMB">
                               <img src="@/assets/images/menu-side-bar-2.svg" alt=""> 
                               </router-link> 
                            </li>
                        </ul>
                </div>
            </div>
        </div>
    </header>
    <!--Mobile Device Menu-->
        <div class="offcanvas offcanvas-start mobileSC_screen" tabindex="-1" id="offcanvasMB" aria-labelledby="offcanvasMBLabel">
          <div class="offcanvas-header mb_sc_mobile">
            <div class="row align-items-center">
              <div class="col-sm-3 col-3">
                  <div class="iconlogomb" > <img src="@/assets/images/icon-logo.svg" alt=""></div>
              </div>
              <div class="col-sm-6 col-6">
                  <h5 class="text-center mblogosc" data-bs-dismiss="offcanvas">
                    <router-link :to="`/${$i18n.locale}`">
                    <img src="@/assets/images/logo-text.svg" alt="">
                    </router-link>
                    </h5>
              </div>
              <div class="col-sm-3 col-3">
                 <div class="mbclose5">
                    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                 </div>
              </div>
            </div> 
          </div>
          <!--START body Tag-->
          <div class="offcanvas-body">
            <!--START Body-->    
              <div class="mbsccontent">
                <div class="scmoblang">
                   <Language />  
                </div>
                 <div class="mbnavsc">
                   <ul>
                       
                            <li data-bs-dismiss="offcanvas"><router-link :to="`/${$i18n.locale}/why`">{{$t('header.nav.why')}}</router-link></li>
                           
                            <li data-bs-dismiss="offcanvas"><router-link :to="`/${$i18n.locale}/our-identity`">{{$t('header.nav.identity')}}</router-link></li>
                            
                  </ul>
                 </div>
                 <!--Btn-->
                  <div class="btnMbsc_off btnbanner">
                    <div class="wscmb100" data-bs-dismiss="offcanvas">
                      <router-link :to="`/${$i18n.locale}/contact-us`" class="fristbtn" >{{$t('header.nav.hireUs')}}</router-link>
                      </div>
                   <div class="wscmb100" data-bs-dismiss="offcanvas">
                    <!-- <router-link :to="`/${$i18n.locale}/why`" class="secondbtn" >{{$t('header.nav.applyTalent')}}</router-link> -->
                      </div>  
                      <p>{{$t('copyRight')}}</p>
                  </div>
                 <!--end-->
              </div>
            <!--END-->
          </div><!--Body tag end-->
        </div> 
    <!--END Mobile menu-->
    <!--Off-Canvas--> 
     <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasSc" aria-labelledby="offcanvasScLabel">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasScLabel"><img src="@/assets/images/offcan-logo.svg" alt=""></h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>
          <!--START body Tag-->
          <div class="offcanvas-body">
            <!--START Body--> 
              <div class="row casesct">
                <div class="col-md-12"><h3>{{$t('header.sidebar.skillTitle')}}</h3></div>
              </div>             
            <Skills/>

          </div><!--Body tag end-->
        </div> 
    <!--END-->
  </div>
</template>

<script>  
import Language from '@/components/Language.vue'
import Skills from '@/components/Skills.vue'  
export default { 

  components: {
    Language, 
    Skills
  },

  data() {
    return {
      menuOpen: false,
      active: false 
    }
  },

  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    },
     toggleNavClass(){
        if(this.active == false){
          return 'nav'
        } else {
          return 'sticky-nav'
        }
    } 
  },
  mounted(){
  window.document.onscroll = () => {
      let navBar = document.getElementById('nav');
      if(window.scrollY > navBar.offsetTop){
        this.active = true;
        } else {
        this.active = false;
      }
    }
  }
}  
</script> 
<style scoped> 
 
</style>