import Vue from "vue";
import Router from "vue-router";
import Home from "./../views/Home.vue";
import NotFound from "../views/404.vue";

Vue.use(Router);

export default new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "/:lang(en|it)?",
			component: {
				render(c) {
					return c("router-view");
				},
			},

			children: [
				{
					path: "/",
					name: "Home",
					component: Home,
				},

				{
					path: "why",
					name: "Why",
					component: () =>
						import("./../views/Why.vue"),
				},
				{
					path: "contact-us",
					name: "Contact Us",
					component: () =>
						import("./../views/Contact_Us.vue"),
				},
				{
					path: "privacy-cookie-policy",
					name: "Privacy & Cookie Policy",
					component: () =>
						import("./../views/Privacy_Policy.vue"),
				},
				{
					path: "our-identity",
					name: "Our Identity",
					component: () =>
						import("./../views/Our_Identity.vue"),
				},
				{
					path: "*/",
					name: "Not Found",
					component: NotFound,
				},
			],
		},
	],
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition;
		} else {
			return { x: 0, y: 0 };
		}
	},
});
