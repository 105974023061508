<template> 
  <div id="app">
    <!--componet base layout-->
        <component :is="layout">
          <router-view :layout.sync="layout"/>
        </component>
        <!--end component-->
  </div>
</template>
<script>
  
export default {
 name: "app",
 
  data() {
    return {
      layout: 'div',
    };
  },
  created() {
    this.$i18n.locale = localStorage.getItem("locale") == null ? navigator.language.substring(0, 2) !== 'it' ? 'en' : localStorage.getItem("locale") == null ? this.$i18n.locale : localStorage.getItem("locale") : this.$i18n.locale;
  }
 
}
</script>
<style>  
</style>
