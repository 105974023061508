<template>
    <footer>
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="footertext-1">                      
                        <img src="@/assets/images/foot-logo.svg" alt="Real Talent">
                         <p  v-html="$t('footer.aboutInfo')"></p> 
                    </div>
                </div>
                <div class="col-md-3 order-1 order-md-12">
                    <div class="footerlinksc">
                        <h3>{{$t('footer.footerTitleOne')}}</h3>
                      <!--  <ul class="socialsc">
                            <li><router-link to="#"><img src="@/assets/images/twitter.svg" alt=""></router-link></li>
                            <li><router-link to="#"><img src="@/assets/images/facebook.svg" alt=""></router-link></li>
                            <li><router-link to="#"><img src="@/assets/images/linkendin.svg" alt=""></router-link></li>
                        </ul> -->
                        <ul class="scaddressinfo">
                            <li><a href='mailto:hello@realtalent.it'>{{$t('footer.email')}}</a></li>
                        
                         <!--   <li><span>Address:</span> Global<br/>
                                <p>21 Imagine Ave., Rome</p>
                            </li> -->
                            <li><a href='tel:0694503568'>{{$t('footer.phone')}}</a></li>                            
                        </ul>
                    </div>
                </div>
                 <div class="col-md-3 order-12 order-md-1">
                    <div class="footerlinksc menulinksc">
                        <h3>{{$t('footer.footerTitleTwo')}}</h3>
                        <ul class="sclink-footer"> 
                            <li><router-link :to="`/${$i18n.locale}/privacy-cookie-policy`">{{$t('footer.footerNavPrivacy')}}</router-link></li>           
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>