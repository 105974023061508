<template>
  <div>

    <div class="row box_skill">
                <!--col-4-->
                  <div class="col-md-5 eightpagh">
                    <!--Category item-->
                    <ul class="freelancer_skill">  
                      <li 
                        v-for="(category, index) in categories" 
                        :key="index"
                        :class="{ active: category === selectedSkills }"
                        @click="filterSkills(category)"> 
                        {{ category }} 
                      </li>  
                    </ul> <!--end-->
                  </div>
                <!--end-->
                <!--col-7--> 
                  <div class="col-md-6 noskillpadding">
                    <div class="scSkill">
                        <div class="scskillsearch"> 
                          <!--start search-->
                          <input
                            type="text" 
                            v-model="search" 
                            @input="$emit('input', $event.target.value)"
                            ref="filter"
                            placeholder="Type a skills to filter the list below"
                          />  
                          <!--end-->
                        </div>
                        <div class="scslikk_list"> 
                          <!--Skill list-->
                          <ul>  
                              <li v-for="(item, idx) in filteredSkills" :key="idx"
                                  :data-alphabet="item.title[0]">
                                <router-link to="#">{{ item.title }} </router-link>  
                              </li> 
                              <span v-if="!filteredSkills.length">No Items Matching!</span>  
                          </ul> <!--end-->
                        </div>
                    </div>
                  </div> 
                <!--end-->
                <!--col-1--> 
                  <div class="col-md-1 alpbg">
                    <div class="scnumberABC">
                        <!--Word A b c-->
                        <ul>
                          <li v-for="(alphabet, index) in alphabet" 
                          :key="index"
                          @click="selectAlphabetically(alphabet)">{{ alphabet }} </li>
                        </ul>
                        <!--end-->
                    </div>
                  </div> 
                <!--end-->
              </div>  

  </div>
</template>

 
<script>
import eng from '../locales/skills_en.json'
import ita from '../locales/skills_it.json'

import engcat from '../locales/cat_en.json'
import itacat from '../locales/cat_it.json'


export default { 
  name: "Skills",
  props: {
    value: String,
  }, 
  data() { 
    return {
      search: null,
      selectedLetter:null,
      selectedSkills: this.$t('selectedSkills'),
      alphabet: ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"],
      eng: eng,
      ita: ita,
      engcat: engcat,
      itacat: itacat, 
    };
  },
  methods: {
    filterSkills(category) { 
      this.selectedSkills = category;
    }, 
    showAll() {
      this.selectedSkills = null;
    },
    selectAlphabetically(alphabet) {
      document.querySelectorAll('[data-alphabet="'+alphabet+'"]')[0].scrollIntoView();
    }
  },
    
  computed: {
    skills() {
      return this.$i18n.locale === 'it' ? this.ita : this.eng;
    },
    categories() {
      return this.$i18n.locale === 'it' ? this.itacat : this.engcat;
    },
    filteredSkills(){
      let result = []
      if(this.search){
            result = this.skills.filter((item)=>{
            return this.search.toLowerCase().split(' ').every(v => 
              item.title.toLowerCase() .includes(v) &&
              item.category  ===  this.selectedSkills  
            ) 
          });
      }else{
         result = this.skills.filter((item)=> item.category === this.selectedSkills)
      }
      return result.sort(((a, b) => a.title.localeCompare(b.title)))
    },
  },

};
</script>