import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import i18n from "./i18n";

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
// Import Bootstrap an BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

//import "@fancyapps/ui/dist/fancybox.css";
//custom fonts
import "@/assets/fonts/fonts.css";

//stylesheet CSS
import "@/assets/css/style.css";
import "@/assets/css/custom.css";
import "@/assets/css/responsive.css";

import "bootstrap/dist/js/bootstrap.min.js";

Vue.config.productionTip = false;

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
	// use the language from the routing param or default language
	let language = to.params.lang;
	if (!language) {
		language = "it";
	}

	// set the current language for i18n.
  i18n.locale = language;
  //if/else
	if (to.path.includes("/it")) {
		next({
			path: to.path.replace("/it", ""),
			replace: true,
		});
	} else {
		next();
	} //end condition
});

new Vue({
	router,
	store,
	i18n,
	render: (h) => h(App),
}).$mount("#app");
